import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
  },
  customSubtitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: '20px',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '4px',
  },
}))

const QualityMonitoringForm = props => {
  const classes = useStyles(props)

  return (
    <>
      <p>FORMA</p>
      <p>KLASA</p>
    </>
  )
}

export default QualityMonitoringForm
