import axios from 'axios'
import { transformSelectedSetup } from '../api/utils'
import moment from 'moment'

const API_URL = `${process.env.REACT_APP_API_URL}`

const History = {
  getHistory(setup, dateRange) {
    const today = moment().startOf('day')
    const startDate =
      moment(dateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00'

    const endDate = moment(dateRange.endDate).isSame(today, 'day')
      ? moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
      : moment(dateRange.endDate).format('YYYY-MM-DD') + ' 23:59:00'

    return axios({
      method: 'get',
      url: `${API_URL}/history/${transformSelectedSetup(
        setup,
      )}?start_date=${startDate}&end_date=${endDate}`,
    })
  },
}

export default History
