import React, { useState } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'

const hiddenColumnsInitially = [
  'Country',
  'Technology',
  'TechnologyGroup',
  'TSO',
  'BalancingCircle',
  'PlantGroup',
  'ActiveFromTimeUTC',
  'ActiveToTimeUTC',
  'AssociatedMaxPower',
  'BasedOn',
  'insertion_time',
]

const typeNumberColumns = [
  'InstalledCapacity',
  'Lat',
  'Lon',
  'AssociatedMaxPower',
]

const CustomToolbar = ({ selectedSetup }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          delimiter: ';',
          fileName: `Portfolio_Overview_${selectedSetup}`,
          utf8WithBom: true,
        }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  )
}

const overridesWeirdResizing = {
  '& .MuiDataGrid-main': {
    width: 0,
    minWidth: '100%',
  },
}

const renameField = key => {
  const renameMap = {
    InstalledCapacityKW: 'InstalledCapacity',
    AssociatedMaxPowerKW: 'AssociatedMaxPower',
  }
  return renameMap[key] || key
}

const PortfolioOverviewTable = props => {
  const { selectedSetup, data, selectedPlants, setSelectedPlants, unit } = props

  const columns = Object.keys(data[0]).map(key => {
    const updKey = renameField(key)
    return {
      field: updKey,
      flex: 1,
      type: typeNumberColumns.includes(updKey) ? 'number' : '',
    }
  })

  const initialColumnVisibilityModel = columns.reduce((acc, obj) => {
    acc[obj.field] = !hiddenColumnsInitially.includes(obj.field)
    return acc
  }, {})

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibilityModel,
  )

  // Change time output format
  const formattedData = data.map(obj => {
    const formatDate = timestamp => {
      return new Date(timestamp).toISOString().slice(0, 16).replace('T', ' ')
    }

    const { InstalledCapacityKW, AssociatedMaxPowerKW, ...filteredObj } = obj

    return {
      ...filteredObj,
      Lat: obj.Lat.toFixed(3),
      Lon: obj.Lon.toFixed(3),
      ActiveFromTimeUTC: formatDate(obj.ActiveFromTimeUTC),
      ActiveToTimeUTC: formatDate(obj.ActiveToTimeUTC),
      insertion_time: formatDate(obj.insertion_time * 1000),
      InstalledCapacity:
        obj.InstalledCapacityKW !== null
          ? parseFloat(
              (unit === 'kW'
                ? obj.InstalledCapacityKW
                : obj.InstalledCapacityKW / 1000
              ).toFixed(2),
            )
          : null,
      AssociatedMaxPower:
        obj.AssociatedMaxPowerKW !== null
          ? parseFloat(
              (unit === 'kW'
                ? obj.AssociatedMaxPowerKW
                : obj.AssociatedMaxPowerKW / 1000
              ).toFixed(2),
            )
          : null,
    }
  })

  return (
    <DataGrid
      rows={formattedData}
      rowHeight={30}
      columns={columns}
      getRowId={row => row.Name}
      checkboxSelection
      rowSelectionModel={selectedPlants}
      onRowSelectionModelChange={setSelectedPlants}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel =>
        setColumnVisibilityModel(newModel)
      }
      disableColumnMenu
      slots={{
        toolbar: () => <CustomToolbar selectedSetup={selectedSetup} />,
      }}
      sx={overridesWeirdResizing}
    />
  )
}

export default PortfolioOverviewTable
