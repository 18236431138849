import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Grid,
  MenuItem,
  FormControl,
  Typography,
  Box,
  Slider,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  CustomInputLabel,
  CustomSelect,
  CustomFormControl,
  CustomTextField,
} from '../restyled-mui/CustomInputs'
import Chip from '@mui/material/Chip'
import FormHelperText from '@mui/material/FormHelperText'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DateRangePicker from './DateRangePicker/DateRangePicker'
import HelpIcon from '@material-ui/icons/Help'
import { PrimaryButton } from '../restyled-mui/CustomButton'
import { Remove as RemoveIcon, Add as AddIcon } from '@material-ui/icons'
import { setSelectedUserSetup } from '../../redux/actions/userSetups'
import {
  setSelectedSetupForecast,
  setSelectedSetupPlant,
  setSelectedSetupForecastType,
  setIssueTime,
  setSelectedPercentile,
  setSelectedDateRange,
  setSelectedAggregationLevel,
  setSelectedAggregationValue,
  setLeadTime,
} from '../../redux/actions/metrics'
import { Autocomplete } from '@mui/material'
import ValueLabelComponent from './DateRangePicker/ValueLabelComponent'
import LinearProgress from '@mui/material/LinearProgress'
import Api from '../../api'
import { transformSelectedSetup } from '../../api/utils'
import { loadStateFromLocalStorage } from '../../redux/utilsLocalStorage'
import moment from 'moment'

const calculateDateRange = dateRangeString => {
  const today = moment() // Fresh instance
  const ranges = {
    d0: {
      startDate: moment(), // Fresh instances for each range
      endDate: moment().add(2, 'days'),
    },
    d1: {
      startDate: moment().subtract(1, 'day'),
      endDate: moment(),
    },
    d2: {
      startDate: moment().subtract(2, 'days'),
      endDate: moment(),
    },
    d3: {
      startDate: moment().subtract(3, 'days'),
      endDate: moment(),
    },
    last_week: {
      // TODO - keep in mind edge case if today is Monday, for this wee there will be just one day
      startDate: moment().startOf('isoWeek'),
      endDate: moment(),
    },
    tw: {
      startDate: moment().startOf('isoWeek').subtract(7, 'days'),
      endDate: moment(),
    },
    t2w: {
      startDate: moment().startOf('isoWeek').subtract(14, 'days'),
      endDate: moment(),
    },
    last_month: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment(),
    },
    t2m: {
      startDate: moment().subtract(2, 'months'),
      endDate: moment(),
    },
    t3m: {
      startDate: moment().subtract(3, 'months'),
      endDate: moment(),
    },
    last_quarter: {
      startDate: moment().subtract(3, 'months'),
      endDate: moment(),
    },
    t2q: {
      startDate: moment().subtract(6, 'months'),
      endDate: moment(),
    },
    t3q: {
      startDate: moment().subtract(9, 'months'),
      endDate: moment(),
    },
    last_year: {
      startDate: moment().subtract(1, 'year'),
      endDate: moment(),
    },
  }

  if (!ranges[dateRangeString]) {
    console.error('Invalid date range string:', dateRangeString)
    return null
  }

  return {
    startDate: ranges[dateRangeString].startDate.toDate(),
    endDate: ranges[dateRangeString].endDate.toDate(),
  }
}

const momentTimezone = require('moment-timezone')
const sitesListWidth = 345
const tabletSidebarWidth = 50

const useStyles = makeStyles(theme => ({
  childrenContainer: {
    marginLeft: props =>
      props.fullscreen || props.toggleable ? 0 : sitesListWidth,
    width: props =>
      props.fullscreen || props.toggleable
        ? '100%'
        : `calc(100% - ${sitesListWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
  },
  drawer: {
    marginLeft: props => (props.toggleable ? tabletSidebarWidth - 1 : 0),
    borderRight: `${theme.palette.menu.border} 1px solid`,
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: tabletSidebarWidth,
    zIndex: 1400,
    backgroundColor: theme.palette.menu.bg,
    borderRight: `${theme.palette.menu.border} 1px solid`,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 4),
  },
  menuItemIcon: {
    textAlign: 'center',
    minWidth: '100%',
  },
  menuIcon: {
    color: theme.palette.menu.icons,
  },
  selectedMenuIcon: {
    color: theme.palette.primary.main,
  },
  tickIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  customSubtitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: '10px',
  },
  timeSlider: {
    marginTop: '33px',
  },
  calendarField: {
    paddingRight: 0,
  },
  checkboxText: {
    color: theme.palette.primary.main,
    marginTop: '12px',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.typography.fontSize,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  customText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    padding: '30px 0px',
  },
  startMetricButton: {
    width: '100%',
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '4px',
  },
  sectionDelimiter: {
    paddingTop: '20px',
  },
  plantsDateErrorCss: {
    margin: '0px 5px',
    color: '#C63C3C',
  },
}))

const MetricsForm = props => {
  const classes = useStyles(props)
  const {
    selectedSetup,
    setSelectedUserSetup,
    userSetups,
    setMetricsParameters,
    handleNoDataContentUpdate,
    setMetricsData,
    onChangeSelectedForecast,
    disableButton,
    selectedSetupForecast,
    setSelectedSetupForecast,
    selectedSetupPlant,
    setSelectedSetupPlant,
    selectedSetupForecastType,
    setSelectedSetupForecastType,
    issueTime,
    setIssueTime,
    selectedPercentile,
    setSelectedPercentile,
    selectedDateRange,
    setSelectedDateRange,
    selectedAggregationLevel,
    setSelectedAggregationLevel,
    selectedAggregationValue,
    setSelectedAggregationValue,
    leadTime,
    setLeadTime,
    trackDisableStatus,
  } = props

  const location = useLocation()

  const forecasts = [
    { name: 'per_plant', label: 'Per Plant' },
    { name: 'aggregated', label: 'Aggregated' },
  ]
  const forecastTypes = ['ID', 'DAH', 'Composite']
  const [timeDisableBtn, setTimeDisableBtn] = useState(false)

  // plants
  const [plants, setPlants] = useState(null)
  const [plantLoading, setPlantLoading] = useState(false)
  const [plantsError, setPlantsError] = useState('')

  // aggregation levels
  const [aggregationLevels, setAggregationLevels] = useState([])
  const [aggregationLevelsLoading, setAggregationLevelsLoading] =
    useState(false)

  // aggregation values
  const [aggregationValues, setAggregationValues] = useState([])
  const [aggregationValuesLoading, setAggregationValuesLoading] =
    useState(false)

  // percentiles
  const [percentiles, setPercentiles] = useState([])
  const [percentilesLoading, setPercentilesLoading] = useState(false)

  const [formState, setFormState] = useState({
    percentError: '',
  })

  const [storedDataReady, setStoredDataReady] = useState(false)
  const buttonRefPlant = useRef(null)
  const buttonRefAggr = useRef(null)
  const [isFullyRendered, setIsFullyRendered] = useState(false)
  const [domChangesDetected, setDomChangesDetected] = useState(false)
  const [fromQualityMonitoringQuery, setFromQualityMonitoringQuety] =
    useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const fromHandler = queryParams.get('fromHandler') === 'true'
    setFromQualityMonitoringQuety(fromHandler)
    const storedData = loadStateFromLocalStorage('quality2MetricsData')

    if (fromHandler && storedData) {
      const { setupName, plantName, forecastType, dateRange } = storedData
      const newDateRange = calculateDateRange(dateRange)
      setSelectedDateRange(newDateRange)
      setSelectedUserSetup(setupName)
      setSelectedSetupPlant(plantName)
      setSelectedSetupForecastType(forecastType)
      setStoredDataReady(true)
    } else {
      console.log('Navigated directly, no handler data')
      setStoredDataReady(true)
    }
  }, [location])

  //TODO - once the data come try out for both buttons
  useEffect(() => {
    if (buttonRefPlant.current || buttonRefAggr.current) {
      setIsFullyRendered(true)
    }
  })

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setDomChangesDetected(true)
    })
    observer.observe(document.body, { childList: true, subtree: true })
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (
      fromQualityMonitoringQuery &&
      isFullyRendered &&
      storedDataReady &&
      domChangesDetected
    ) {
      startMetrics()
    }
  }, [
    isFullyRendered,
    storedDataReady,
    domChangesDetected,
    fromQualityMonitoringQuery,
  ])

  const validateForm = () => {
    const errors = {
      percentError:
        selectedPercentile.length === 0
          ? 'Metrics calculation requires a minimum of 50% selection.'
          : '',
    }
    setFormState(prevForm => ({ ...prevForm, ...errors }))
    return Object.values(errors).every(error => !error)
  }

  const timezone =
    loadStateFromLocalStorage('timezone') !== undefined
      ? loadStateFromLocalStorage('timezone')
      : 'Etc/UTC'
  const date = new Date()
  const localTime = momentTimezone(date).tz(timezone)
  const timeZoneOffsetMinutes = localTime.utcOffset()
  // change the sign because the positive offset means from UTC to the local time
  const oppositeOffsetMinutes =
    timeZoneOffsetMinutes !== 0 ? timeZoneOffsetMinutes * -1 : 0

  const handleForecastChange = event => {
    const selectedForecastName = event.target.value
    const selectedForecastObject = forecasts.find(
      forecast => forecast.name === selectedForecastName,
    )
    setSelectedSetupForecast(selectedForecastObject)
    onChangeSelectedForecast(selectedForecastObject)
    setSelectedSetupPlant(null)
    setSelectedAggregationLevel(null)
    setSelectedSetupForecastType(forecastTypes[0])
    setMetricsData(null)
    handleNoDataContentUpdate({ heading: '', body: '' })
  }

  const marks = [
    { value: 0, label: '00:00' },
    { value: 1435, label: '23:55' },
  ]

  const deletePercentile = value => {
    setSelectedPercentile(selectedPercentile.filter(item => item !== value))
  }

  const handleChangeLeadTime = e => {
    setMetricsData(null)
    const inputValue = parseInt(e.target.value, 10)

    if (!isNaN(inputValue)) {
      const nonNegativeValue = Math.max(0, inputValue)

      setLeadTime(nonNegativeValue)
    } else {
      setLeadTime(0)
    }
  }

  const handleSetupListData = (_, newValue) => {
    setSelectedSetupPlant(null)
    setSelectedAggregationLevel(null)
    setSelectedUserSetup(newValue)
    if (newValue !== null) {
      handleNoDataContentUpdate({ heading: '', body: '' })
    } else {
      handleNoDataContentUpdate({
        heading: 'No setup selected',
        body: 'Select a setup and adjust parameters in order to start analysis',
      })
    }
  }

  // get setup aggregation levels
  useEffect(() => {
    const fetchAggregationLevel = async () => {
      setAggregationLevelsLoading(true)
      try {
        const response = await Api.Metrics.getClientAggregationLevels(
          selectedSetup,
        )
        setAggregationLevels(response.data)
        setAggregationLevelsLoading(false)
      } catch (error) {
        setAggregationLevels([])
        console.error(
          'Error fetching aggregation levels for selected setup:',
          error,
        )
      } finally {
        setAggregationLevelsLoading(false)
      }
    }
    if (selectedSetup !== null) {
      fetchAggregationLevel()
    } else {
      setAggregationLevels([])
    }
  }, [selectedSetup])

  // get setup aggregation values
  useEffect(() => {
    const fetchAggregationValues = async () => {
      setAggregationValuesLoading(true)
      try {
        const response = await Api.Metrics.getClientAggregationValues(
          selectedSetup,
          selectedAggregationLevel,
        )
        setAggregationValues(response.data)
        setAggregationValuesLoading(false)
      } catch (error) {
        setAggregationValues([])
        console.error(
          'Error fetching aggregation level for selected setup:',
          error,
        )
      } finally {
        setAggregationValuesLoading(false)
      }
    }
    if (selectedSetup !== null && selectedAggregationLevel !== null) {
      fetchAggregationValues()
    } else {
      setAggregationValues([])
    }
  }, [selectedSetup, selectedAggregationLevel])

  // get setup plants
  useEffect(() => {
    const fetchPlants = async () => {
      setPlantLoading(true)
      try {
        // const response = await axios.get('/metrics.json')
        const response = await Api.Metrics.getSetupPlants(
          selectedSetup,
          selectedDateRange,
        )

        const startDate = moment(selectedDateRange.startDate).format(
          'YYYY-MM-DD',
        )
        const endDate = moment(selectedDateRange.endDate).format('YYYY-MM-DD')

        // until get proper response
        if (response.status === 200) {
          if (response.data.length !== 0) {
            setPlants(response.data)
            handleNoDataContentUpdate({ heading: '', body: '' })
            // setPlantsDateError('')
            setPlantsError('')
            // setDsbBtn(false)
            // setInitialPlantsLoad(false)
          } else {
            handleNoDataContentUpdate({
              heading: 'Info:',
              body: 'Please change the DATE SELECTION to get the active plants.',
            })
            setPlantsError(
              `No plants for selected period: ${startDate} to ${endDate}.`,
            )

            /* 
            this code was with this initial Plant Load as a 
            flag to get different errors and content for the 
            previous version where date selection was at the end of cycles
            */
            // if (initialPlantsLoad) {
            //   handleNoDataContentUpdate({ heading: '', body: '' })
            //   setInitialPlantsLoad(false)
            //   setPlantsError(
            //     `No plants for selected period: ${startDate} to ${endDate}.`,
            //   )
            //   // setPlantsDateError('')
            // } else {
            //   setDsbBtn(true)
            //   setPlantsError('')
            //   // setPlantsDateError('No plants for selected period.')
            //   handleNoDataContentUpdate({
            //     heading: 'Info:',
            //     body: 'Please change the DATE SELECTION to get the active plants.',
            //   })
            // }
            setPlants(null)
          }
        }
        setPlantLoading(false)
      } catch (error) {
        setPlants(null)
        console.error('Error fetching plants for selected setup:', error)
      } finally {
        setPlantLoading(false)
      }
    }
    if (selectedSetup !== null) {
      fetchPlants()
    } else {
      setPlants(null)
    }
  }, [selectedSetup, selectedDateRange])

  // get setup percentiles
  useEffect(() => {
    const fetchPercentiles = async () => {
      setPercentilesLoading(true)
      try {
        const response = await Api.Metrics.getSetupPercentiles(
          selectedSetup,
          selectedSetupForecast,
        )

        setPercentiles(response.data)
        setPercentilesLoading(false)
      } catch (error) {
        setPercentiles([])
        console.error('Error fetching percentiles for selected setup:', error)
      } finally {
        setPercentilesLoading(false)
      }
    }
    if (selectedSetup !== null) {
      fetchPercentiles()
    } else {
      setPercentiles([])
    }
  }, [selectedSetup, selectedSetupForecast])

  const createMetricsObject = () => {
    const initObj = {
      client: transformSelectedSetup(selectedSetup),
      forecast: selectedSetupForecast.name,
      forecast_type: selectedSetupForecastType,
      percentiles: selectedPercentile,
      start_date: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
      end_date: moment(selectedDateRange.endDate).format('YYYY-MM-DD'),
    }
    if (selectedSetupForecast.name === 'per_plant') {
      return selectedSetupForecastType === 'Composite'
        ? {
            ...initObj,
            plant: selectedSetupPlant,
            lead_time: leadTime,
            issue_time: 600, // hardcoded just for this case
          }
        : {
            ...initObj,
            plant: selectedSetupPlant,
            issue_time: issueTime + oppositeOffsetMinutes,
          }
    } else {
      const commonProps = {
        ...initObj,
        aggregation_level: selectedAggregationLevel,
        aggregation_value: aggregationValues.find(
          item => selectedAggregationValue in item,
        )?.[selectedAggregationValue],
      }

      return selectedSetupForecastType === 'Composite'
        ? {
            ...commonProps,
            lead_time: leadTime,
          }
        : {
            ...commonProps,
            issue_time: issueTime + oppositeOffsetMinutes,
          }
    }
  }

  const startMetrics = () => {
    if (validateForm()) {
      setMetricsParameters(createMetricsObject())
    }
  }

  const isEmptyOrContainsUndefined =
    userSetups.length === 0 || userSetups.some(x => x === undefined)

  return (
    <>
      {storedDataReady && (
        <div className={classes.formContainer}>
          <Grid container spacing={0}>
            <Grid item md={12}>
              <Typography
                variant="h4"
                className={clsx(classes.formSubtitle, classes.customSubtitle)}
                style={{ marginBottom: 0 }}
              >
                Setup selection
              </Typography>
              {!isEmptyOrContainsUndefined ? (
                <Grid item md={12}>
                  <div className={classes.tooltipContainer}>
                    <CustomInputLabel>Setup Name</CustomInputLabel>
                    <Tooltip
                      title="You can search for a value by typing in the box!"
                      placement="top"
                      arrow
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.arrow,
                      }}
                    >
                      <span>
                        <IconButton
                          style={{ padding: '0' }}
                          aria-label="info"
                          disableTouchRipple
                        >
                          <HelpIcon
                            fontSize="small"
                            className={classes.helpIcon}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Autocomplete
                      disabled={trackDisableStatus}
                      variant="outlined"
                      size="small"
                      options={userSetups}
                      value={selectedSetup}
                      autoHighlight
                      onChange={handleSetupListData}
                      renderInput={params => (
                        <CustomTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: { padding: '1px 5px' },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <CustomInputLabel>
                  No available setup for selection or error occurred.
                </CustomInputLabel>
              )}

              {!isEmptyOrContainsUndefined && (
                <Grid item md={12}>
                  <CustomInputLabel>Forecast</CustomInputLabel>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    size="small"
                    disabled={trackDisableStatus}
                  >
                    <CustomSelect
                      inputProps={{
                        margin: 'dense',
                      }}
                      value={selectedSetupForecast.name}
                      onChange={handleForecastChange}
                      name={'forecastId'}
                    >
                      {forecasts.map(forecast => {
                        return (
                          <MenuItem
                            key={forecast.name}
                            value={forecast.name}
                            className={classes.selectMenuItem}
                          >
                            {forecast.label}
                          </MenuItem>
                        )
                      })}
                    </CustomSelect>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid item md={12} className={classes.sectionDelimiter}>
              <Typography
                variant="h4"
                className={clsx(classes.formSubtitle, classes.customSubtitle)}
              >
                Date selection
              </Typography>
              <Grid container spacing={2} style={{ paddingBottom: '15px' }}>
                <DateRangePicker
                  trackDisableStatus={trackDisableStatus}
                  initialDateRange={selectedDateRange}
                  onDateRangeChange={dateRange => {
                    setSelectedDateRange(dateRange)
                    setMetricsData(null)
                  }}
                  onErrorChange={setTimeDisableBtn}
                />
                {/* <CustomInputLabel className={classes.plantsDateErrorCss}>
                {plantsDateError}
              </CustomInputLabel> */}
              </Grid>
            </Grid>

            {selectedSetup !== null &&
              selectedSetupForecast.name === 'per_plant' && (
                <Grid item md={12} className={classes.sectionDelimiter}>
                  <Typography
                    variant="h4"
                    className={clsx(
                      classes.formSubtitle,
                      classes.customSubtitle,
                    )}
                  >
                    Forecast Type Selection
                  </Typography>

                  <Grid item md={12}>
                    {plantLoading ? (
                      <LinearProgress style={{ marginTop: '20px' }} />
                    ) : plants !== null ? (
                      <>
                        <CustomInputLabel>Plant</CustomInputLabel>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Autocomplete
                            disabled={trackDisableStatus}
                            variant="outlined"
                            size="small"
                            options={plants}
                            value={selectedSetupPlant}
                            autoHighlight
                            onChange={(_, newPlant) => {
                              setSelectedSetupPlant(newPlant)
                              setMetricsData(null)
                            }}
                            renderInput={params => (
                              <CustomTextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  style: { padding: '1px 5px' },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    ) : (
                      <CustomInputLabel style={{ color: 'orangered' }}>
                        {plantsError}
                      </CustomInputLabel>
                    )}
                  </Grid>
                  {selectedSetupPlant !== null && (
                    <Grid item md={12}>
                      <CustomInputLabel>Forecast Type</CustomInputLabel>
                      <FormControl
                        disabled={trackDisableStatus}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        size="small"
                      >
                        <CustomSelect
                          inputProps={{
                            margin: 'dense',
                          }}
                          value={selectedSetupForecastType}
                          onChange={e => {
                            setSelectedSetupForecastType(e.target.value)
                            setMetricsData(null)
                          }}
                          name={'forecastTypeId'}
                        >
                          {forecastTypes.map(ftype => {
                            return (
                              <MenuItem
                                key={ftype}
                                value={ftype}
                                className={classes.selectMenuItem}
                              >
                                {ftype}
                              </MenuItem>
                            )
                          })}
                        </CustomSelect>
                      </FormControl>
                    </Grid>
                  )}
                  {selectedSetupPlant !== null &&
                    (selectedSetupForecastType === 'ID' ||
                      selectedSetupForecastType === 'DAH') && (
                      <Grid item md={12}>
                        <CustomInputLabel>
                          Issuetime ({timezone})
                        </CustomInputLabel>
                        <Box className={classes.timeSlider}>
                          <Slider
                            disabled={trackDisableStatus}
                            aria-label="Always visible"
                            value={issueTime}
                            onChange={(_, newSliderValue) => {
                              setIssueTime(newSliderValue)
                              setMetricsData(null)
                            }}
                            defaultValue={600}
                            step={5}
                            marks={marks}
                            valueLabelDisplay="on"
                            min={0}
                            max={1435}
                            ValueLabelComponent={ValueLabelComponent}
                          />
                        </Box>
                      </Grid>
                    )}
                  {selectedSetupPlant !== null &&
                    selectedSetupForecastType === 'Composite' && (
                      <Grid item md={12}>
                        <CustomInputLabel>Leadtime (minutes)</CustomInputLabel>
                        <TextField
                          disabled={trackDisableStatus}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          size="small"
                          value={leadTime}
                          onChange={handleChangeLeadTime}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  aria-label="decrease"
                                  onClick={() => {
                                    const newValue = Math.max(leadTime - 15, 0)
                                    setLeadTime(newValue)
                                    setMetricsData(null)
                                  }}
                                  disabled={
                                    leadTime - 15 < 0 || trackDisableStatus
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                                <IconButton
                                  disabled={trackDisableStatus}
                                  size="small"
                                  aria-label="increase"
                                  onClick={() => {
                                    const newValue = leadTime + 15
                                    setLeadTime(newValue)
                                    setMetricsData(null)
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    )}
                  {selectedSetupPlant !== null && (
                    <Grid item md={12}>
                      <CustomInputLabel>Percentiles</CustomInputLabel>
                      {percentilesLoading ? (
                        <LinearProgress style={{ marginTop: '20px' }} />
                      ) : percentiles.length !== 0 ? (
                        <>
                          <CustomFormControl
                            disabled={trackDisableStatus}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                            error={Boolean(formState.percentError)}
                          >
                            <CustomSelect
                              inputProps={{
                                margin: 'dense',
                              }}
                              value={selectedPercentile}
                              onChange={e => {
                                setSelectedPercentile(e.target.value)
                                setMetricsData(null)
                              }}
                              name={'percentilesId'}
                              multiple
                              renderValue={selected => {
                                return (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map(value => (
                                      <Chip
                                        disabled={trackDisableStatus}
                                        key={value}
                                        label={value}
                                        onDelete={() => {
                                          deletePercentile(value)
                                          setMetricsData(null)
                                        }}
                                        onMouseDown={e => {
                                          e.stopPropagation()
                                        }}
                                      />
                                    ))}
                                    {selected.length > 0 && (
                                      <IconButton
                                        disabled={trackDisableStatus}
                                        size="small"
                                        onClick={() => {
                                          setSelectedPercentile([])
                                          setMetricsData(null)
                                        }}
                                        onMouseDown={e => {
                                          e.stopPropagation()
                                        }}
                                        aria-label="Clear All"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                )
                              }}
                            >
                              {percentiles.map(percentile => {
                                return (
                                  <MenuItem
                                    key={percentile}
                                    value={percentile}
                                    className={classes.selectMenuItem}
                                  >
                                    {percentile}
                                    {selectedPercentile.includes(percentile) ? (
                                      <CheckIcon
                                        color="info"
                                        disableButton={trackDisableStatus}
                                      />
                                    ) : null}
                                  </MenuItem>
                                )
                              })}
                            </CustomSelect>
                          </CustomFormControl>
                          <FormHelperText id="component-error-text">
                            {formState.percentError}
                          </FormHelperText>
                        </>
                      ) : (
                        <CustomInputLabel>
                          No available percentiles for selected setup.
                        </CustomInputLabel>
                      )}
                    </Grid>
                  )}
                  {selectedSetupPlant !== null &&
                    !percentilesLoading &&
                    percentiles.length !== 0 && (
                      <Grid item md={12} className={classes.sectionDelimiter}>
                        <Grid item md={12} className={classes.sectionDelimiter}>
                          <PrimaryButton
                            ref={buttonRefPlant}
                            onClick={startMetrics}
                            style={{
                              background:
                                (disableButton || timeDisableBtn) && '#ccc',
                            }}
                            className={classes.startMetricButton}
                            disabled={disableButton || timeDisableBtn}
                          >
                            RUN
                          </PrimaryButton>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              )}

            {/* ###############################################################################################3 */}

            {selectedSetup !== null &&
              selectedSetupForecast.name === 'aggregated' && (
                <Grid item md={12} className={classes.sectionDelimiter}>
                  <Typography
                    variant="h4"
                    className={clsx(
                      classes.formSubtitle,
                      classes.customSubtitle,
                    )}
                  >
                    Forecast Type Selection
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item md={12}>
                      <CustomInputLabel>Aggregation Level</CustomInputLabel>
                      {aggregationLevelsLoading ? (
                        <LinearProgress style={{ marginTop: '20px' }} />
                      ) : aggregationLevels.length !== 0 ? (
                        <FormControl fullWidth variant="outlined" size="small">
                          <Autocomplete
                            disabled={trackDisableStatus}
                            variant="outlined"
                            size="small"
                            options={aggregationLevels}
                            value={selectedAggregationLevel}
                            autoHighlight
                            onChange={(_, newAggrLevel) => {
                              setSelectedAggregationLevel(newAggrLevel)
                              setSelectedAggregationValue('')
                              setMetricsData(null)
                            }}
                            renderInput={params => (
                              <CustomTextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  style: { padding: '1px 5px' },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      ) : (
                        <CustomInputLabel>
                          No aggregation levels for selected setup or error
                          occurred.
                        </CustomInputLabel>
                      )}
                    </Grid>
                    {selectedAggregationLevel !== null && (
                      <Grid item md={12}>
                        <CustomInputLabel style={{ marginBottom: '4px' }}>
                          Aggregation Value
                        </CustomInputLabel>
                        {aggregationValuesLoading ? (
                          <LinearProgress style={{ marginTop: '20px' }} />
                        ) : aggregationValues.length !== 0 ? (
                          <FormControl
                            disabled={trackDisableStatus}
                            fullWidth
                            variant="outlined"
                            size="small"
                          >
                            <CustomSelect
                              inputProps={{
                                margin: 'dense',
                              }}
                              value={selectedAggregationValue}
                              onChange={e => {
                                setSelectedAggregationValue(e.target.value)
                                setMetricsData(null)
                              }}
                              name={'aggrValue'}
                              defaultValue=""
                            >
                              {aggregationValues.map(obj => {
                                const key = Object.keys(obj)[0]
                                return (
                                  <MenuItem
                                    key={key}
                                    value={key}
                                    className={classes.selectMenuItem}
                                  >
                                    {key}
                                  </MenuItem>
                                )
                              })}
                            </CustomSelect>
                          </FormControl>
                        ) : (
                          <CustomInputLabel>
                            No aggregation values for selected setup or error
                            occurred.
                          </CustomInputLabel>
                        )}
                      </Grid>
                    )}

                    {selectedAggregationLevel !== null &&
                      selectedAggregationValue !== '' &&
                      aggregationValues.length !== 0 && (
                        <Grid item md={12}>
                          <CustomInputLabel>Forecast Type</CustomInputLabel>
                          <FormControl
                            disabled={trackDisableStatus}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                          >
                            <CustomSelect
                              inputProps={{
                                margin: 'dense',
                              }}
                              value={selectedSetupForecastType}
                              onChange={e => {
                                setSelectedSetupForecastType(e.target.value)
                                setMetricsData(null)
                              }}
                              name={'forecastType2'}
                            >
                              {forecastTypes.map(ftype => {
                                return (
                                  <MenuItem
                                    key={ftype}
                                    value={ftype}
                                    className={classes.selectMenuItem}
                                  >
                                    {ftype}
                                  </MenuItem>
                                )
                              })}
                            </CustomSelect>
                          </FormControl>
                        </Grid>
                      )}

                    {selectedAggregationLevel !== null &&
                      selectedAggregationValue !== '' &&
                      (selectedSetupForecastType === 'ID' ||
                        selectedSetupForecastType === 'DAH') &&
                      aggregationValues.length !== 0 && (
                        <Grid item md={12}>
                          <CustomInputLabel>
                            Issuetime ({timezone})
                          </CustomInputLabel>
                          <Box className={classes.timeSlider}>
                            <Slider
                              disabled={trackDisableStatus}
                              aria-label="Always visible"
                              value={issueTime}
                              onChange={(_, newSliderValue) => {
                                setIssueTime(newSliderValue)
                                setMetricsData(null)
                              }}
                              defaultValue={600}
                              step={5}
                              marks={marks}
                              valueLabelDisplay="on"
                              min={0}
                              max={1435}
                              ValueLabelComponent={ValueLabelComponent}
                            />
                          </Box>
                        </Grid>
                      )}

                    {selectedAggregationLevel !== null &&
                      selectedAggregationValue !== '' &&
                      selectedSetupForecastType === 'Composite' &&
                      aggregationValues.length !== 0 && (
                        <Grid item md={12}>
                          <CustomInputLabel>
                            Leadtime (minutes)
                          </CustomInputLabel>
                          <TextField
                            disabled={trackDisableStatus}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                            value={leadTime}
                            onChange={handleChangeLeadTime}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    aria-label="decrease"
                                    onClick={() => {
                                      const newValue = Math.max(
                                        leadTime - 15,
                                        0,
                                      )
                                      setLeadTime(newValue)
                                      setMetricsData(null)
                                    }}
                                    disabled={
                                      leadTime - 15 < 0 || trackDisableStatus
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  <IconButton
                                    disabled={trackDisableStatus}
                                    size="small"
                                    aria-label="increase"
                                    onClick={() => {
                                      const newValue = leadTime + 15
                                      setLeadTime(newValue)
                                      setMetricsData(null)
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      )}

                    {selectedAggregationLevel !== null &&
                      selectedAggregationValue !== '' &&
                      aggregationValues.length !== 0 && (
                        <Grid item md={12}>
                          <CustomInputLabel>Percentiles</CustomInputLabel>
                          {percentilesLoading ? (
                            <LinearProgress style={{ marginTop: '20px' }} />
                          ) : percentiles.length !== 0 ? (
                            <>
                              <CustomFormControl
                                disabled={trackDisableStatus}
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                size="small"
                                error={Boolean(formState.percentError)}
                              >
                                <CustomSelect
                                  inputProps={{
                                    margin: 'dense',
                                  }}
                                  value={selectedPercentile}
                                  onChange={e => {
                                    setSelectedPercentile(e.target.value)
                                    setMetricsData(null)
                                  }}
                                  name={'percentileId2'}
                                  multiple
                                  renderValue={selected => {
                                    return (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          gap: 0.5,
                                        }}
                                      >
                                        {selected.map(value => (
                                          <Chip
                                            disabled={trackDisableStatus}
                                            key={value}
                                            label={value}
                                            onDelete={() => {
                                              deletePercentile(value)
                                              setMetricsData(null)
                                            }}
                                            onMouseDown={e => {
                                              e.stopPropagation()
                                            }}
                                          />
                                        ))}
                                        {selected.length > 0 && (
                                          <IconButton
                                            disabled={trackDisableStatus}
                                            size="small"
                                            onClick={() => {
                                              setSelectedPercentile([])
                                              setMetricsData(null)
                                            }}
                                            onMouseDown={e => {
                                              e.stopPropagation()
                                            }}
                                            aria-label="Clear All"
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        )}
                                      </Box>
                                    )
                                  }}
                                >
                                  {percentiles.map(percentile => {
                                    return (
                                      <MenuItem
                                        key={percentile}
                                        value={percentile}
                                        className={classes.selectMenuItem}
                                      >
                                        {percentile}
                                        {selectedPercentile.includes(
                                          percentile,
                                        ) ? (
                                          <CheckIcon color="info" />
                                        ) : null}
                                      </MenuItem>
                                    )
                                  })}
                                </CustomSelect>
                              </CustomFormControl>
                              <FormHelperText id="component-error-text">
                                {formState.percentError}
                              </FormHelperText>
                            </>
                          ) : (
                            <CustomInputLabel>
                              No available percentiles for selected setup.
                            </CustomInputLabel>
                          )}
                        </Grid>
                      )}
                    {selectedAggregationLevel !== null &&
                      selectedAggregationValue !== '' &&
                      !percentilesLoading &&
                      aggregationValues.length !== 0 &&
                      percentiles.length !== 0 && (
                        <Grid item md={12} className={classes.sectionDelimiter}>
                          <Grid
                            item
                            md={12}
                            className={classes.sectionDelimiter}
                          >
                            <PrimaryButton
                              ref={buttonRefAggr}
                              onClick={startMetrics}
                              style={{ background: disableButton && '#ccc' }}
                              className={classes.startMetricButton}
                              disabled={disableButton}
                            >
                              RUN
                            </PrimaryButton>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              )}
            {/* ###############################################################################################3 */}
          </Grid>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    selectedSetup: state.userSetups.selectedSetup,
    selectedSetupForecast: state.metrics.selectedForecast,
    selectedSetupPlant: state.metrics.selectedPlant,
    selectedSetupForecastType: state.metrics.selectedForecastType,
    issueTime: state.metrics.issueTime,
    selectedPercentile: state.metrics.selectedPercentile,
    selectedDateRange: state.metrics.selectedDateRange,
    selectedAggregationLevel: state.metrics.selectedAggregationLevel,
    selectedAggregationValue: state.metrics.selectedAggregationValue,
    leadTime: state.metrics.leadTime,
  }
}

const mapDispatchToProps = {
  setSelectedUserSetup,
  setSelectedSetupForecast,
  setSelectedSetupPlant,
  setSelectedSetupForecastType,
  setIssueTime,
  setSelectedPercentile,
  setSelectedDateRange,
  setSelectedAggregationLevel,
  setSelectedAggregationValue,
  setLeadTime,
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsForm)
