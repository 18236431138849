import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import QualityMonitringSetups from '../features/quality-monitoring/QualityMonitringSetups'
import QualityMonitringSetupPlants from '../features/quality-monitoring/QualityMonitringSetupPlants'
import PageNotFound from './PageNotFound'
import Api from '../api'
import { CircularProgress } from '@material-ui/core'

const tabsMap = [
  { id: '1', name: 'ID' },
  { id: '2', name: 'DAH' },
  { id: '3', name: 'Composite' },
]

const QMApp = props => {
  const { history, container } = props
  const [validSetupsIds, setValidSetupsIds] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [allSetups, setAllSetups] = useState([])
  const [mainTabValue, setMainTabValue] = useState(tabsMap[0])

  const goToHomepage = () => {
    history.push('/monitoring')
  }

  useEffect(() => {
    const fetchAllSetups = async () => {
      try {
        const response = await Api.Setups.getSetups()
        const allSetups = response.data.setups
        setAllSetups(allSetups)
        const setupsIDs = allSetups.map(setup => setup.id).sort((a, b) => a - b)
        setValidSetupsIds(setupsIDs)
      } catch (error) {
        console.error('Error fetching client setups:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAllSetups()
  }, [])

  return (
    <>
      <Switch>
        <Route exact path={`/monitoring`}>
          <Redirect to={`/monitoring/setups`} />
        </Route>

        <Route
          path={`/monitoring/setups`}
          exact
          render={props => (
            <QualityMonitringSetups
              {...props}
              container={container}
              mainTabValue={mainTabValue}
              setMainTabValue={setMainTabValue}
              tabsMap={tabsMap}
            />
          )}
        />

        <Route
          path={`/monitoring/setups/:id`}
          exact
          render={props => {
            const { id } = props.match.params

            if (isLoading) {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                    width: '100%',
                  }}
                >
                  <CircularProgress />
                </div>
              )
            }
            const parsedId = parseInt(id, 10)
            const isWholeNumber = /^\d+$/.test(id) // Ensure `id` is only numeric

            if (!isWholeNumber) {
              return <Redirect to="/monitoring/setups" />
            }

            const isValidId = validSetupsIds.includes(parsedId)
            if (!isValidId) {
              return <Redirect to="/monitoring/setups" />
            }

            return (
              <QualityMonitringSetupPlants
                {...props}
                allSetups={allSetups}
                mainTabValue={mainTabValue}
                setMainTabValue={setMainTabValue}
                tabsMap={tabsMap}
              />
            )
          }}
        />

        <Route render={props => <PageNotFound goToHomepage={goToHomepage} />} />
      </Switch>
    </>
  )
}

export default withRouter(QMApp)
