import React, { useState } from 'react'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PortfolioHistoryForm from '../components/forms/PortfolioHistoryForm'

const sitesListWidth = 345
const tabletSidebarWidth = 50

const useStyles = makeStyles(theme => ({
  childrenContainer: {
    marginLeft: props =>
      props.fullscreen || props.toggleable ? 0 : sitesListWidth,
    width: props =>
      props.fullscreen || props.toggleable
        ? '100%'
        : `calc(100% - ${sitesListWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
    marginRight: '50px',
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: props =>
      props.fullscreen
        ? '100%'
        : `calc(${sitesListWidth} - ${theme.spacing(4)}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    marginLeft: props => (props.toggleable ? tabletSidebarWidth - 1 : 0),
    borderRight: `${theme.palette.menu.border} 1px solid`,
    width: props => (props.fullscreen ? '100%' : sitesListWidth),
  },
}))

const PortfolioHistoryPanel = props => {
  const classes = useStyles(props)
  const { container, userSetups, trackDisableStatus, ...dateRange } = props
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <Drawer
        open={true}
        variant={'permanent'}
        anchor={'left'}
        ModalProps={{
          keepMounted: true,
          container: container.current,
          style: { position: 'absolute' },
        }}
        PaperProps={{
          style: { position: 'absolute' },
          className: classes.drawerPaper,
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        onClose={toggleOpen}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.formContainer}>
          <PortfolioHistoryForm
            userSetups={userSetups}
            trackDisableStatus={trackDisableStatus}
            {...dateRange}
          ></PortfolioHistoryForm>
        </div>
      </Drawer>
      <div className={classes.childrenContainer}>{props.children}</div>
    </>
  )
}

export default PortfolioHistoryPanel
