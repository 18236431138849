import axios from 'axios'
import { transformSelectedSetup } from '../api/utils'

const API_URL = `${process.env.REACT_APP_API_URL}`

const dataMockID = [
  {
    id: 141,
    setup: 'statkraft_de_wind',
    d0: 10,
    d1: 12,
    d2: 14,
    d3: 16,
    last_week: 11,
    tw: 12,
    t2w: 14,
    last_month: 16,
    t2m: 12,
    t3m: 11,
    last_quarter: 11,
    t2q: 33,
    t3q: 94,
    last_year: 11.5,
    total: 12,
  },
  {
    id: 5,
    setup: 'statkraft_de_solar',
    d0: 25,
    d1: 14,
    d2: 74,
    d3: 85,
    last_week: 9,
    tw: 13,
    t2w: 25,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 13,
  },
  {
    id: 7,
    setup: 'statkraft_fr_wind',
    d0: 15,
    d1: 14,
    d2: 24,
    d3: 45,
    last_week: 9,
    tw: 53,
    t2w: 65,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 56,
    last_year: 61.5,
    total: 77.6,
  },
]

const dataMockDAH = [
  {
    id: 21,
    setup: 'sunnic_solar',
    d0: 22,
    d1: 23,
    d2: 14,
    d3: 34,
    last_week: 55,
    tw: 44,
    t2w: 33,
    last_month: 36,
    t2m: 56,
    t3m: 78,
    last_quarter: 37,
    t2q: 47,
    t3q: 43,
    last_year: 11.5,
    total: 66,
  },
  {
    id: 124,
    setup: 'tauron_wind',
    d0: 25,
    d1: 5,
    d2: 74,
    d3: 55,
    last_week: 9,
    tw: 13,
    t2w: 11,
    last_month: 37,
    t2m: 56,
    t3m: 46,
    last_quarter: 77,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 77,
  },
  {
    id: 88,
    setup: 'totalenergies',
    d0: 33,
    d1: 22,
    d2: 24,
    d3: 45,
    last_week: 9,
    tw: 53,
    t2w: 65,
    last_month: 17,
    t2m: 29,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 66,
    last_year: 61.5,
    total: 77.6,
  },
]

const dataMockComposite = [
  {
    id: 58,
    setup: 'vasavind_wind',
    d0: 22,
    d1: 23,
    d2: 14,
    d3: 34,
    last_week: 55,
    tw: 44,
    t2w: 33,
    last_month: 36,
    t2m: 56,
    t3m: 78,
    last_quarter: 37,
    t2q: 47,
    t3q: 43,
    last_year: 11.5,
    total: 33,
  },
  {
    id: 36,
    setup: 'pzem_wind',
    d0: 25,
    d1: 5,
    d2: 74,
    d3: 55,
    last_week: 9,
    tw: 13,
    t2w: 11,
    last_month: 37,
    t2m: 56,
    t3m: 46,
    last_quarter: 77,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 43,
  },
  {
    id: 8,
    setup: 'ilmatar_wind',
    d0: 33,
    d1: 22,
    d2: 24,
    d3: 45,
    last_week: 9,
    tw: 53,
    t2w: 11,
    last_month: 17,
    t2m: 29,
    t3m: 1,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 6,
    last_year: 61.5,
    total: 45,
  },
]

const statkraft_de_wind = [
  {
    id: 1,
    plant: 'WP_100002',
    d0: 25,
    d1: 14,
    d2: 74,
    d3: 85,
    last_week: 9,
    tw: 13,
    t2w: 25,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 13,
  },
  {
    id: 3,
    plant: 'WP_100005',
    d0: 25,
    d1: 14,
    d2: 74,
    d3: 85,
    last_week: 9,
    tw: 13,
    t2w: 25,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 13,
  },
  {
    id: 4,
    plant: 'WP_100006',
    d0: 25,
    d1: 14,
    d2: 74,
    d3: 85,
    last_week: 9,
    tw: 13,
    t2w: 25,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 13,
  },
]

const statkraft_de_solar = [
  {
    id: 1,
    plant: 'SP_100017',
    d0: 10,
    d1: 12,
    d2: 14,
    d3: 16,
    last_week: 11,
    tw: 12,
    t2w: 14,
    last_month: 16,
    t2m: 12,
    t3m: 11,
    last_quarter: 11,
    t2q: 33,
    t3q: 94,
    last_year: 11.5,
    total: 12,
  },
  {
    id: 2,
    plant: 'SP_100059',
    d0: 25,
    d1: 14,
    d2: 74,
    d3: 85,
    last_week: 9,
    tw: 13,
    t2w: 25,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 13,
  },
]

const test = [
  {
    id: 1,
    plant: 'test_petar001',
    d0: 10,
    d1: 12,
    d2: 14,
    d3: 16,
    last_week: 11,
    tw: 12,
    t2w: 14,
    last_month: 16,
    t2m: 12,
    t3m: 11,
    last_quarter: 11,
    t2q: 33,
    t3q: 94,
    last_year: 11.5,
    total: 12,
  },
  {
    id: 2,
    plant: 'test_petar002',
    d0: 25,
    d1: 14,
    d2: 74,
    d3: 85,
    last_week: 9,
    tw: 13,
    t2w: 25,
    last_month: 17,
    t2m: 36,
    t3m: 41,
    last_quarter: 11,
    t2q: 10.5,
    t3q: 51,
    last_year: 61.5,
    total: 13,
  },
]

const QualityMonitoring = {
  getQualityMonitoringSetups(dataType) {
    // return axios({
    //   method: 'get',
    //   url: `${API_URL}/monitoring/get_setups`,
    // })
    return new Promise((resolve, reject) => {
      if (dataType === 'ID') {
        setTimeout(() => {
          resolve({ data: dataMockID })
        }, 2000)
      } else if (dataType === 'DAH') {
        setTimeout(() => {
          resolve({ data: dataMockDAH })
        }, 1000)
      } else {
        setTimeout(() => {
          resolve({ data: dataMockComposite })
        }, 3000)
      }
    })
  },

  getQualityMonitoringSetupPlants(setup, dataType) {
    return new Promise((resolve, reject) => {
      if (dataType === 'ID') {
        setTimeout(() => {
          resolve({ data: statkraft_de_wind })
        }, 2000)
      } else if (dataType === 'DAH') {
        setTimeout(() => {
          resolve({ data: statkraft_de_solar })
        }, 1000)
      } else {
        setTimeout(() => {
          resolve({ data: test })
        }, 3000)
      }
    })
  },
}

export default QualityMonitoring
