import {
  portfolioOverviewIcon,
  portfolioOverviewIconSelected,
} from '../assets/fontawesome/portfolioOverviewIcons'

import {
  metricsIcon,
  metricsIconSelected,
} from '../assets/fontawesome/metricsIcons'

import {
  analysisIcon,
  analysisIconSelected,
} from '../../src/assets/fontawesome/analysisIcons'

import {
  historyIcon,
  historyIconSelected,
} from '../assets/fontawesome/historyIcons'

import {
  qualityMonitoringIcon,
  qualityMonitoringIconSelected,
} from '../assets/fontawesome/qualityMonitoring'

export const modules = {
  overview: {
    id: 'overview',
    url: '/overview',
    name: 'Portfolio Overview',
    title: 'Portfolio Overview',
    icon: {
      default: portfolioOverviewIcon,
      selected: portfolioOverviewIconSelected,
    },
    route: 1,
  },
  history: {
    id: 'history',
    url: '/history',
    name: 'Portfolio History',
    title: 'Portfolio History',
    icon: {
      default: historyIcon,
      selected: historyIconSelected,
    },
    route: 2,
  },
  metrics: {
    id: 'metrics',
    url: '/metrics',
    name: 'Metrics',
    title: 'Metrics',
    icon: {
      default: metricsIcon,
      selected: metricsIconSelected,
    },
    route: 3,
  },
  analysis: {
    id: 'analysis',
    url: '/analysis',
    name: 'Analysis',
    title: 'Analysis',
    icon: {
      default: analysisIcon,
      selected: analysisIconSelected,
    },
    route: 4,
  },
  monitoring: {
    id: 'monitoring',
    url: '/monitoring',
    name: 'Quality Monitoring',
    title: 'Quality Monitoring',
    icon: {
      default: qualityMonitoringIcon,
      selected: qualityMonitoringIconSelected,
    },
    route: 5,
  },
  settings: {
    id: 'settings',
    url: '/settings',
    name: 'Settings',
    title: 'Settings',
    route: 50,
  },
  wiki: {
    id: 'wiki',
    url: '/wiki',
    name: 'Wiki',
    title: 'Wiki',
    route: 60,
  },
}

export const moduleIsAvailable = (moduleId, availableModules) => {
  // const sortForId = availableModules.map(m => m.toLowerCase())
  return availableModules.includes(moduleId)
}

export const getModulePermissions = () => {
  const moduleList = []
  for (const moduleId in modules) {
    // wiki module will be available for any user
    if (moduleId !== 'wiki') {
      const capitalizedId = moduleId.charAt(0).toUpperCase() + moduleId.slice(1)
      moduleList.push(capitalizedId)
    }
  }
  return moduleList
}

export default getModulePermissions
