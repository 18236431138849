import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Box, Tab } from '@material-ui/core'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid'
import QualityMonitoringPanel from '../../panels/QualityMonitoringPanel'
import axios from 'axios'
import Api from '../../api'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { borderColor, padding } from '@mui/system'

const getColor = value => {
  const hue = (((100 - value) * 120) / 100).toString(10)
  return ['hsl(', hue, ',100%,50%)'].join('')
}

const columns = [
  {
    field: 'setup',
    headerName: 'Setups',
    flex: 1.2,
  },
  {
    field: 'd0',
    headerName: 'D-0',
    flex: 0.2,
  },
  {
    field: 'd1',
    headerName: 'D-1',
    flex: 0.2,
  },
  {
    field: 'd2',
    headerName: 'D-2',
    flex: 0.2,
  },
  {
    field: 'd3',
    headerName: 'D-3',
    flex: 0.2,
  },
  {
    field: 'last_week',
    headerName: 'Last week',
    flex: 0.8,
  },
  {
    field: 'tw',
    headerName: 'T-W',
    flex: 0.5,
  },
  {
    field: 't2w',
    headerName: 'T-2W',
    flex: 0.5,
  },
  {
    field: 'last_month',
    headerName: 'Last month',
    flex: 0.8,
  },
  {
    field: 't2m',
    headerName: 'T-2M',
    flex: 0.5,
  },
  {
    field: 't3m',
    headerName: 'T-3M',
    flex: 0.5,
  },
  {
    field: 'last_quarter',
    headerName: 'Last quarter',
    flex: 1,
  },
  {
    field: 't2q',
    headerName: 'T-2Q',
    flex: 0.5,
  },
  {
    field: 't3q',
    headerName: 'T-3Q',
    flex: 0.5,
  },
  {
    field: 'last_year',
    headerName: 'Last year',
    flex: 0.8,
  },
  {
    field: 'total',
    headerName: 'TOTAL',
    flex: 1,
  },
]

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: '100%',
    width: '100%',
    padding: '10px 20px',
  },
  tableContainer: {
    height: '85vh',
    width: '100%',
    overflowY: 'hidden',
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    width: '100%',
  },
  customTabPanel: {
    '&.MuiTabPanel-root': {
      padding: '30px 0px',
    },
  },
}))

const overridesWeirdResizing = {
  '& .MuiDataGrid-main': {
    width: 0,
    minWidth: '100%',
  },
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
}

const CustomToolbar = ({ tableName }) => {
  const formattedDate = new Date().toISOString().split('T')[0]
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          delimiter: ';',
          fileName: `${formattedDate}_${tableName}`,
          utf8WithBom: true, // Optional: Add BOM for UTF-8 encoding
        }}
        printOptions={{
          allColumns: true,
          hideFooter: true, // Optionally hide footer during print
          hideToolbar: true, // Optionally hide toolbar during print
          // label: 'PDF',
          // disableToolbarButton: true
        }}
      />
    </GridToolbarContainer>
  )
}

const QualityMonitoringSetups = props => {
  const classes = useStyles(props)
  const location = useLocation()
  const { history, mainTabValue, setMainTabValue, tabsMap } = props
  const [qualityMonitoringData, setQualityMonitoringData] = useState(null)
  const [loading, setLoading] = useState(false)

  const colorizeData = columns.map(item => ({
    ...item,
    renderCell: params => {
      const color = getColor(params.value)

      return (
        <Box
          sx={{
            backgroundColor: color,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {params.value}
        </Box>
      )
    },
    headerAlign: 'center',
  }))

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await Api.QualityMonitoring.getQualityMonitoringSetups(
          mainTabValue.name,
        )
        // const response = await axios.get('/qualityMonitoringSetups.json')
        setQualityMonitoringData(response.data)
        setLoading(false)
      } catch (error) {
        console.error(`Error while fetching ${mainTabValue.name}:`, error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [mainTabValue])

  const handleSelectedSetup = params => {
    history.push(`${location.pathname}/${params.id}`)
  }

  const handleMainTabChange = (_, newValue) => {
    const selectedTab = tabsMap.find(tab => tab.id === newValue)
    setMainTabValue(selectedTab)
  }

  const renderDataGrid = () => {
    if (loading) {
      return (
        <div className={classes.centerItem}>
          <CircularProgress />
        </div>
      )
    }
    if (qualityMonitoringData) {
      return (
        <div className={classes.tableContainer}>
          <DataGrid
            rows={qualityMonitoringData}
            rowHeight={30}
            columns={colorizeData}
            getRowId={row => row.id}
            disableColumnSelector
            disableColumnMenu
            slots={{
              toolbar: props => (
                <CustomToolbar {...props} tableName={mainTabValue.name} />
              ),
            }}
            sx={overridesWeirdResizing}
            onRowClick={handleSelectedSetup}
            localeText={{
              toolbarExportPrint: 'Print as PDF',
              toolbarExport: 'Export',
            }}
          />
        </div>
      )
    }
    return null
  }

  return (
    <div className={classes.mainContainer}>
      <TabContext value={mainTabValue.id}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleMainTabChange}
            aria-label="Quality monitoring"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#368ED8',
              },
            }}
          >
            <Tab
              style={{
                color: mainTabValue.id === '1' ? '#368ED8' : 'inherit',
              }}
              label="ID"
              value="1"
            />
            <Tab
              style={{
                color: mainTabValue.id === '2' ? '#368ED8' : 'inherit',
              }}
              label="DAH"
              value="2"
            />
            <Tab
              style={{
                color: mainTabValue.id === '3' ? '#368ED8' : 'inherit',
              }}
              label="Composite"
              value="3"
            />
          </TabList>

          <TabPanel value="1" className={classes.customTabPanel}>
            <div>{renderDataGrid()}</div>
          </TabPanel>

          <TabPanel value="2" className={classes.customTabPanel}>
            <div>{renderDataGrid()}</div>
          </TabPanel>

          <TabPanel value="3" className={classes.customTabPanel}>
            <div>{renderDataGrid()}</div>
          </TabPanel>
        </Box>
      </TabContext>
    </div>
  )
}

export default QualityMonitoringSetups
