import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { calendarIcon } from '../../../assets/fontawesome/calendarIcon'
import { makeStyles } from '@material-ui/core/styles'
import { getSettingsStyles } from '../../../styles/settingsStyles'
import { CustomKeyboardDatePicker } from '../../restyled-mui/CustomInputs'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  ...getSettingsStyles(theme),
  endAdornmentIcon: {
    height: 21,
    width: 21,
    color: theme.palette.generalUi.icons,
  },
  calendarField: {
    paddingRight: 0,
  },
}))

const isValidDate = date => {
  return date && date.isValid && date.isValid()
}

const parseDate = date => {
  if (!date) return null
  const parsedDate = moment(date)
  return parsedDate.isValid() ? parsedDate : null
}

const DateRangePicker = ({
  initialDateRange,
  onDateRangeChange,
  trackDisableStatus,
  onErrorChange = () => {},
}) => {
  const classes = useStyles()
  const [dateRange, setDateRange] = useState({
    startDate: moment(initialDateRange.startDate),
    endDate: moment(initialDateRange.endDate),
  })
  const [error, setError] = useState({ startDate: null, endDate: null })

  const handleDateChange = (date, isStartDate) => {
    const parsedDate = parseDate(date)

    if (!parsedDate) {
      setError(prevError => ({
        ...prevError,
        [isStartDate ? 'startDate' : 'endDate']: 'Invalid Date Format',
      }))
      onErrorChange(true)
      return
    }

    if (isStartDate) {
      if (parsedDate.isAfter(dateRange.endDate)) {
        setError(prevError => ({
          ...prevError,
          startDate: 'Start date cannot be after end date',
        }))
        onErrorChange(true)
        return
      }
      setError(prevError => ({ ...prevError, startDate: null }))
      setDateRange(prevDateRange => ({
        ...prevDateRange,
        startDate: parsedDate,
      }))
      onDateRangeChange({
        startDate: parsedDate.toDate(),
        endDate: dateRange.endDate.toDate(),
      })
    } else {
      if (parsedDate.isBefore(dateRange.startDate)) {
        setError(prevError => ({
          ...prevError,
          endDate: 'End date cannot be before start date',
        }))
        onErrorChange(true)
        return
      }
      setError(prevError => ({ ...prevError, endDate: null }))
      setDateRange(prevDateRange => ({
        ...prevDateRange,
        endDate: parsedDate,
      }))
      onDateRangeChange({
        startDate: dateRange.startDate.toDate(),
        endDate: parsedDate.toDate(),
      })
    }
    onErrorChange(false)
  }

  return (
    <>
      <Grid item md={6}>
        <CustomKeyboardDatePicker
          disabled={trackDisableStatus}
          inputVariant="outlined"
          margin="dense"
          label="From"
          value={dateRange.startDate}
          onChange={date => handleDateChange(date, true)}
          format="yyyy-MM-DD"
          fullWidth
          error={!!error.startDate}
          helperText={error.startDate || ''}
          InputProps={{
            className: classes.calendarField,
          }}
          keyboardIcon={
            <FontAwesomeIcon
              icon={calendarIcon}
              className={classes.endAdornmentIcon}
            />
          }
        />
      </Grid>
      <Grid item md={6}>
        <CustomKeyboardDatePicker
          disabled={trackDisableStatus}
          inputVariant="outlined"
          margin="dense"
          label="To"
          value={dateRange.endDate}
          onChange={date => handleDateChange(date, false)}
          format="yyyy-MM-DD"
          fullWidth
          error={!!error.endDate}
          helperText={error.endDate || ''}
          InputProps={{
            className: classes.calendarField,
          }}
          keyboardIcon={
            <FontAwesomeIcon
              icon={calendarIcon}
              className={classes.endAdornmentIcon}
            />
          }
        />
      </Grid>
    </>
  )
}

export default DateRangePicker
