import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import { CustomButton } from '../../components/restyled-mui/CustomButton'

const useStyles = makeStyles(theme => ({
  textContent: {
    whiteSpace: 'pre-wrap',
    paddingBottom: 24,
  },
  dialogActionPanel: {
    backgroundColor: theme.palette.generalUi.actionPanelBackground,
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  cancelButton: {
    margin: theme.spacing(0, 2, 0, 0),
    backgroundColor: '#00000000',
    color: theme.palette.text.defaultSub,
    '&:hover': {
      color: theme.palette.text.default,
      backgroundColor: '#00000020',
    },
  },
  defaultButton: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  warningButton: {
    margin: theme.spacing(0, 2, 0, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.mainHover,
    },
  },
}))

export const initialConfirmDialog = {
  open: false,
  confirmCallback: null,
  cancelCallback: null,
  title: '',
  content: '',
  color: '',
}

const ConfirmationDialog = props => {
  const classes = useStyles(props)
  const {
    open,
    title,
    content,
    color,
    onClose,
    confirmCallback,
    cancelCallback,
    type,
    ...other
  } = props

  const handleCancel = reason => {
    if (cancelCallback) {
      cancelCallback() // Ensure it resets the dialog state
    } else if (onClose) {
      onClose(reason)
    }
  }

  const handleOk = () => {
    confirmCallback()
  }

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      transitionDuration={0}
      {...other}
    >
      <DialogTitle variant="h3">{title}</DialogTitle>
      <DialogContent className={classes.textContent}>
        {cancelCallback && (
          <IconButton
            aria-label="Close"
            onClick={handleCancel}
            className={classes.closeButton}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        {content}
      </DialogContent>
      <DialogActions className={classes.dialogActionPanel}>
        {cancelCallback && (
          <CustomButton
            autoFocus
            variant="contained"
            disableElevation
            onClick={handleCancel}
            color="secondary"
            className={classes.cancelButton}
          >
            Cancel
          </CustomButton>
        )}
        <CustomButton
          variant="contained"
          disableElevation
          onClick={handleOk}
          color="primary"
          className={
            type === 'warning' ? classes.warningButton : classes.defaultButton
          }
        >
          Ok
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
