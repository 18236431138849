import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.generalUi.panelBackground,
    border: `1px solid ${theme.palette.generalUi.borders}`,
    borderRadius: 4,
  },
  title: {
    fontSize: 15,
    color: theme.palette.generalUi.text,
    fontWeight: 500,
  },
  content: {
    fontSize: 25,
    color: theme.palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: 8,
  },
}))

const InfoBox = props => {
  const { title, content } = props

  const classes = useStyles()

  // Format number with commas to make it more readable. Allow maximum of 2 decimal places.
  const formatNumber = number => {
    if (number === undefined) {
      return 'N/A'
    }
    // Reduce the number of decimal places, only if it has more than 2
    if (number % 1 !== 0) {
      number = number.toFixed(2)
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '')
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{formatNumber(content)}</div>
    </div>
  )
}

export default InfoBox
