import axios from 'axios'
import Analysis from './analysis'
import History from './history'
import Overview from './overview'
import Metrics from './metrics'
import Setups from './setups'
import Users from './users'
import QualityMonitoring from './monitoring'

// const API_URL = 'https://dev.forecastui.conwx.com'
const API_URL = process.env.REACT_APP_API_URL

const Api = {
  authenticateUser(data) {
    return axios({
      method: 'post',
      url: `${API_URL}/login`,
      data,
    })
  },
  Analysis,
  History,
  Overview,
  Metrics,
  Setups,
  Users,
  QualityMonitoring,
}

export default Api
