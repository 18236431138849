import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PortfolioOverviewPanel from '../features/portfolio-overview-panel/PortfolioOverviewPanel'
import NoSite from '../components/NoSite'
import { Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PortfolioOverviewTable from '../features/portfolio-overview-table/PortfolioOverviewTable'
import GlobalMap from '../charts/PortfolioOverview/GlobalMap'
import InfoBox from '../common/dashboard/InfoBox'
import { setSelectedUserSetup } from '../redux/actions/userSetups'
import Api from '../api'
import { loadStateFromLocalStorage } from '../redux/utilsLocalStorage'

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainGrid: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
  },
  tableGrid: {
    height: '35%',
    display: 'flex',
  },
  mapGrid: {
    height: '55%',
    display: 'flex',
  },
  dataGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  },
  icons: {
    transform: 'scale(0.5)',
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  alignNoData: {
    marginRight: '50px',
  },
}))

const getNormalizedUnit = unit => {
  const unitMapping = {
    kW: 'kW',
    kWh: 'kW',
    MW: 'MW',
    MWh: 'MW',
  }
  return unitMapping[unit] || 'kW'
}

const PortfolioOverviewPage = props => {
  const { container, selectedSetup, userSetups, setSelectedUserSetup } = props
  const classes = useStyles(props)
  const [tableData, setTableData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedPlants, setSelectedPlants] = useState([])
  const [mapData, setMapData] = useState([])
  const [nodataContent, setNodataContent] = useState({})

  const storedUnit = loadStateFromLocalStorage('unit') || 'kW'
  const selectedUnit = getNormalizedUnit(storedUnit)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await Api.Overview.getOverview(selectedSetup)

        if (response.status === 204) {
          setTableData(null)
          setNodataContent({
            heading: `No data for selected setup: ${selectedSetup}`,
            body: '',
          })
        }
        if (response.status === 200) {
          setTableData(response.data)
        }
        setLoading(false)
      } catch (error) {
        setTableData(null)
        if (error.response.status === 411) {
          setNodataContent({
            heading: `Error running query for selected setup: ${selectedSetup}`,
            body: '',
          })
        } else {
          setNodataContent({
            heading: `Error fetching data for selected setup: ${selectedSetup}`,
            body: '',
          })
        }
        console.error('Error fetching portfolio overview data:', error)
      } finally {
        setLoading(false)
      }
    }

    if (selectedSetup !== null) {
      fetchData()
    } else {
      setSelectedPlants([])
      setNodataContent({
        heading: 'No data',
        body: 'Select a setup to view portfolio overview',
      })
    }
  }, [selectedSetup])

  // Prepare the data for the map on update of
  // the table data or the selected plants
  useEffect(() => {
    if (selectedPlants && selectedPlants.length > 0) {
      setMapData(
        tableData
          .filter(plant => selectedPlants.includes(plant.Name))
          .map(plant => ({
            latitude: plant.Lat,
            longitude: plant.Lon,
            name: plant.Name,
            installedCapacity: parseFloat(
              (selectedUnit === 'kW'
                ? plant.InstalledCapacityKW
                : plant.InstalledCapacityKW / 1000
              ).toFixed(2),
            ),
            technology: plant.Technology,
          })),
      )
    } else if (tableData && tableData.length > 0) {
      setMapData(
        tableData.map(plant => ({
          latitude: plant.Lat,
          longitude: plant.Lon,
          name: plant.Name,
          installedCapacity: parseFloat(
            (selectedUnit === 'kW'
              ? plant.InstalledCapacityKW
              : plant.InstalledCapacityKW / 1000
            ).toFixed(2),
          ),
          technology: plant.Technology,
        })),
      )
    }
  }, [tableData, selectedPlants])

  const resultPanel = () => {
    if (
      tableData === null ||
      tableData.length === 0 ||
      selectedSetup === null
    ) {
      return (
        <div className={classes.centerItem}>
          <div className={classes.alignNoData}>
            <NoSite
              {...props}
              creating={false}
              heading={nodataContent.heading}
              body={nodataContent.body}
            ></NoSite>
          </div>
        </div>
      )
    }

    // Calculate total capacity
    let totalCapacity = 0
    tableData.forEach(plant => {
      totalCapacity += plant['InstalledCapacityKW']
    })

    let selectedCapacity = 0
    selectedPlants.forEach(plant => {
      // Find the plant in the table data
      const plantData = tableData.find(p => p.Name === plant)
      selectedCapacity += plantData['InstalledCapacityKW']
    })

    return (
      <Grid
        container
        spacing={2}
        className={classes.mainGrid}
        alignItems="center"
      >
        {/* --------------------------------------------------- */}
        {/* ---------------------- MAP ------------------------ */}
        {/* --------------------------------------------------- */}
        <Grid item xs={8} className={classes.mapGrid}>
          <GlobalMap data={mapData} unit={selectedUnit} />
        </Grid>
        <Grid item xs={4} className={classes.dataGrid}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <InfoBox title="Number of Plants" content={tableData.length} />
            </Grid>
            <Grid item xs={6}>
              <InfoBox
                title={`Total Capacity (${selectedUnit})`}
                content={
                  selectedUnit === 'kW' ? totalCapacity : totalCapacity / 1000
                }
              />
            </Grid>
            {/* ---- DIVIDER ---- */}
            <Grid item xs={12} style={{ height: '25px' }} />
            {/* ---- DIVIDER ---- */}
            <Grid item xs={6}>
              <InfoBox
                title="Selected Plants"
                content={selectedPlants.length}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoBox
                title={`Selected Capacity (${selectedUnit})`}
                content={
                  selectedUnit === 'kW'
                    ? selectedCapacity
                    : selectedCapacity / 1000
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {/* --------------------------------------------------- */}
        {/* --------------------- TABLE ----------------------- */}
        {/* --------------------------------------------------- */}
        <Grid item xs={12} className={classes.tableGrid}>
          <PortfolioOverviewTable
            selectedSetup={selectedSetup}
            data={tableData}
            selectedPlants={selectedPlants}
            setSelectedPlants={setSelectedPlants}
            unit={selectedUnit}
          />
        </Grid>
      </Grid>
    )
  }

  // If there is only 1 available setup, select it automatically
  if (userSetups.length === 1) {
    setSelectedUserSetup(userSetups[0])
  }

  // If there is only 1 setup, there is no point on having the drawer
  // so we hide it
  if (userSetups.length === 1) {
    if (!loading) {
      return resultPanel()
    }
    return (
      <div className={classes.centerItem}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <PortfolioOverviewPanel
      container={container}
      userSetups={userSetups}
      setSetup={setSelectedUserSetup}
      selectedSetup={selectedSetup}
      loading={loading}
    >
      {!loading ? (
        resultPanel()
      ) : (
        <div className={classes.centerItem}>
          <CircularProgress />
        </div>
      )}
    </PortfolioOverviewPanel>
  )
}

const mapStateToProps = state => ({
  selectedSetup: state.userSetups.selectedSetup,
  userSetups: state.userSetups.userSetups,
})

const mapDispatchToProps = {
  setSelectedUserSetup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortfolioOverviewPage)
